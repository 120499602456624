import React from "react";
import axios from "../../interceptors/interceptors";
import { useEffect, useState } from "react";
import "../PatientList/style.css";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import MyResponsivePie from "./PieChart";
import StatusBars from "./StatusBar";
import AppointmentWidget from "./TotalAppointment";
import MyResponsiveLine from "./lineChart";
import MyResponsiveRadialBar from "./RadialBarChart";
import MyResponsiveBar from "./ageBarChart";
import MlrbResponsiveBar from "./mlrbBarChart";
import {
  Collapse,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BarDetailsModal from "./barDetailsModal";

const getOneMonthAgo = () => {
  const currentDate = dayjs();
  const oneMonthAgo = currentDate.subtract(1, "month");
  return oneMonthAgo;
};

const getOneDayAgo = () => {  
  const currentDate = dayjs();  
  const oneDayAgo = currentDate.subtract(1, "day");  
  return oneDayAgo;  
};  

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const groupAgesIntoRanges = (ageData) => {
  const ageRanges = {};

  ageData.forEach((item) => {
    const age = parseInt(item.id, 10);
    const rangeStart = Math.floor(age / 5) * 5;
    const rangeEnd = rangeStart + 5;
    const rangeLabel = `${rangeStart}-${rangeEnd}`;

    if (!ageRanges[rangeLabel]) {
      ageRanges[rangeLabel] = 0;
    }

    ageRanges[rangeLabel] += item.value;
  });

  return ageRanges;
};

const APCApp = (props) => {
  const [feedbackPage, setFeedbackPage] = useState(0);
  const [feedbackRowsPerPage, setFeedbackRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [feedbackData, setFeedbackData] = useState([]);
  const [startDate, setStartDate] = useState(getOneDayAgo());
  const [genderData, setGenderData] = useState([]);
  const [raceData, setRaceData] = useState([]);
  const [ethnicityData, setEthnicityData] = useState([]);
  const [ageData, setAgeData] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [averageOverallScore, setAverageOverallScore] = useState(0);
  const [totalPatients, setTotalPatients] = useState(0);
  const [endDate, setEndDate] = useState(dayjs().add(1, "day"));
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalCompleted, setTotalCompleted] = useState(0);
  const [totalScheduled, setTotalScheduled] = useState(0);
  const [totalApptScheduled, setTotalApptScheduled] = useState(0);
  const [totalRescheduled, setTotalRescheduled] = useState(0);
  const [totalCancelled, setTotalCancelled] = useState(0);
  const [barChartData, setBarChartData] = useState([]);
  const [mlrbBarChartData, setMlrbBarChartData] = useState([]);
  const [selectedBarSection, setSelectedBarSection] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [mlrbData, setMlrbData] = useState([]);

  const removeDuplicateGuids = (data) => {
    const latestGuids = new Map();
    data.forEach((item) => {
      if (latestGuids.has(item.guid)) {
        const existingItem = latestGuids.get(item.guid);
        if (new Date(item.processedDate) > new Date(existingItem.processedDate)) {
          latestGuids.set(item.guid, item);
        }
      } else {
        latestGuids.set(item.guid, item);
      }
    });

    const filteredData = Array.from(latestGuids.values());
    return filteredData;
  };

  const processMlrbBarChartData = (apiData) => {
    const scoreRanges = {
      "<0.5": 0,
      "0.5-0.7": 0,
      "0.7-0.9": 0,
      ">0.9": 0,
    };

    const scoreKeys = [
      "demographic_score",
      "labs_score",
      "social_history_score",
      "family_history_score",
      "visit_objective_score",
      "medications_score",
      "conditions_score",
    ];

    const scoreLabels = {
      demographic_score: "Demographic",
      labs_score: "Labs",
      social_history_score: "Social History",
      family_history_score: "Family History",
      visit_objective_score: "Visit Objective",
      medications_score: "Medications",
      conditions_score: "Conditions",
    };

    const scoresCount = {};

    scoreKeys.forEach((key) => {
      scoresCount[key] = { ...scoreRanges };
    });

    apiData.forEach((item) => {
      scoreKeys.forEach((key) => {
        const score = item[key];
        if (score < 0.5) {
          scoresCount[key]["<0.5"]++;
        } else if (score >= 0.5 && score < 0.7) {
          scoresCount[key]["0.5-0.7"]++;
        } else if (score >= 0.7 && score < 0.9) {
          scoresCount[key]["0.7-0.9"]++;
        } else {
          scoresCount[key][">0.9"]++;
        }
      });
    });

    const chartData = scoreKeys.map((key) => {
      const ranges = scoresCount[key];
      return {
        mlrbScore: scoreLabels[key],
        ...ranges,
      };
    });

    setMlrbBarChartData(chartData);
    console.log("MLRB Bar Chart Data:", chartData);
  };

  const handleFeedbackChangePage = (event, newPage) => {
    setFeedbackPage(newPage);
  };

  const handleFeedbackChangeRowsPerPage = (event) => {
    setFeedbackRowsPerPage(+event.target.value);
    setFeedbackPage(0);
  };

  useEffect(() => {
    setTotalCompleted(data.reduce((acc, cur) => acc + cur["cip"], 0));
    setTotalScheduled(data.reduce((acc, cur) => acc + cur["scheduled"], 0));
    setTotalApptScheduled(data.reduce((acc, cur) => acc + cur["apptscheduled"], 0));
    setTotalRescheduled(data.reduce((acc, cur) => acc + cur["rescheduled"], 0));
    setTotalCancelled(data.reduce((acc, cur) => acc + cur["cancelled"], 0));
  }, [data]);

  useEffect(() => {
    if (ageData.length > 0) {
      const ageRanges = groupAgesIntoRanges(ageData);
      const chartData = Object.entries(ageRanges).map(([range, count]) => ({
        country: range,
        "age count": count,
      }));
      setBarChartData(chartData);
    } else {
      setBarChartData([]);
    }
  }, [ageData]);

  useEffect(() => {
    getAPCApp();
    getData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const calculateFeedbackStats = (feedbackData) => {
    let totalAppointments = 0;
    let totalFeedbacks = 0;
    let positiveFeedbacks = 0;
    let negativeFeedbacks = 0;

    feedbackData.forEach((row) => {
      totalAppointments += 1;
      totalFeedbacks += row.feedback.length;
      row.feedback.forEach((feedback) => {
        if (feedback.action === "like") {
          positiveFeedbacks += 1;
        } else if (feedback.action === "dislike") {
          negativeFeedbacks += 1;
        }
      });
    });

    return {
      totalAppointments,
      totalFeedbacks,
      positiveFeedbacks,
      negativeFeedbacks,
    };
  };

  const [feedbackStats, setFeedbackStats] = useState({
    totalAppointments: 0,
    totalFeedbacks: 0,
    positiveFeedbacks: 0,
    negativeFeedbacks: 0,
  });

  const getData = async () => {
    const formattedStartDate = startDate.format("YYYY-MM-DD");
    const formattedEndDate = endDate.format("YYYY-MM-DD");

    try {
      const response = await axios.post(
        process.env.REACT_APP_generate_feedback_summary,
        {
          StartDate: formattedStartDate,
          EndDate: formattedEndDate,
        }
      );
      console.log("response", response.data);
      setFeedbackData(response.data.feedback_summary);
      setFeedbackStats(calculateFeedbackStats(response.data.feedback_summary));

      const mlrbResponse = await axios.post(
        process.env.REACT_APP_getReport,
        {
          apptStartDate: formattedStartDate,
          apptEndDate: formattedEndDate,
        }
      );
      const uniqueData = removeDuplicateGuids(mlrbResponse.data.ReportingData);
      setMlrbData(uniqueData);
      processMlrbBarChartData(uniqueData);
    } catch (e) {
      console.log(e);
    }
  };

  const getAPCApp = async () => {
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    try {
      setIsLoading(true);
      const response = await axios.post(
        process.env.REACT_APP_generateApcStatus,
        {
          apptStartDate: formattedStartDate,
          apptEndDate: formattedEndDate,
          apcId: "",
        }
      );
      let sum = 0;
      let uniqapcid = [];
      const tempStore = response.data.hcdata.map((item) => {
        sum += item.LoginCount;
        if (!uniqapcid.includes(item.APCID)) {
          uniqapcid.push(item.APCID);
        }
        item = {
          name:
            item.fname +
            " " +
            item.lname +
            " ",
          apcID: item.APCID,
          status: item.status,
          appointmentId: item.appointmentID,
        };
        return item;
      });
      let apcidAppointmentStats = {};

      tempStore.forEach((item) => {
        const apcid = item.apcID;
        if (!apcidAppointmentStats[apcid]) {
          apcidAppointmentStats[apcid] = {
            count: 0,
            Scheduled: 0,
            "Appointment Scheduled": 0,
            "Reschedule Member Called": 0,
            "Assessment Sent to CIP": 0,
            Cancelled: 0,
            name: item.name,
          };
        }
        apcidAppointmentStats[apcid].count++;
        if (apcidAppointmentStats[apcid][item.status] !== undefined) {
          apcidAppointmentStats[apcid][item.status]++;
        }
      });
      const resultArray = Object.entries(apcidAppointmentStats).map(([apcid, counts]) => ({
        apcid: apcid,
        count: counts.count,
        scheduled: counts["Scheduled"],
        cip: counts["Assessment Sent to CIP"],
        apptscheduled: counts["Appointment Scheduled"],
        rescheduled: counts["Reschedule Member Called"],
        cancelled: counts["Cancelled"],
        statuscount: [
          counts["Scheduled"],
          counts["Appointment Scheduled"],
          counts["Reschedule Member Called"],
          counts["Assessment Sent to CIP"],
          counts["Cancelled"],
        ],
        name: counts.name,
      }));
      setData(resultArray);

      const genderResponse = await axios.post(
        process.env.REACT_APP_getFlattenedDataByApptDate,
        {
          apptStartDate: formattedStartDate,
          apptEndDate: formattedEndDate,
        }
      );
      const genderStats = genderResponse.data.hcdata.reduce((acc, cur) => {
        if (!acc[cur.Gender]) {
          acc[cur.Gender] = new Set();
        }
        acc[cur.Gender].add(cur.patientID);
        return acc;
      }, {});
      const genderDataArray = Object.entries(genderStats).map(([gender, patientIDs]) => ({
        id: gender,
        label: gender,
        value: patientIDs.size,
      }));
      setGenderData(genderDataArray);

      const raceStats = genderResponse.data.hcdata.reduce((acc, cur) => {
        if (!acc[cur.race]) {
          acc[cur.race] = new Set();
        }
        acc[cur.race].add(cur.patientID);
        return acc;
      }, {});
      const raceDataArray = Object.entries(raceStats).map(([race, patientIDs]) => ({
        id: race,
        label: race,
        value: patientIDs.size,
      }));
      setRaceData(raceDataArray);

      const ethnicityStats = genderResponse.data.hcdata.reduce((acc, cur) => {
        if (!acc[cur.ethnicity]) {
          acc[cur.ethnicity] = new Set();
        }
        acc[cur.ethnicity].add(cur.patientID);
        return acc;
      }, {});
      const ethnicityDataArray = Object.entries(ethnicityStats).map(([ethnicity, patientIDs]) => ({
        id: ethnicity,
        label: ethnicity,
        value: patientIDs.size,
      }));
      setEthnicityData(ethnicityDataArray);

      const ageStats = genderResponse.data.hcdata.reduce((acc, cur) => {
        if (!acc[cur.age]) {
          acc[cur.age] = new Set();
        }
        acc[cur.age].add(cur.patientID);
        return acc;
      }, {});

      const ageDataArray = Object.entries(ageStats).map(([age, patientIDs]) => ({
        id: parseInt(age),
        label: age,
        value: patientIDs.size,
      }));
      setAgeData(ageDataArray);

      const dateScoreMap = genderResponse.data.hcdata.reduce((acc, cur) => {
        const date = cur.appointmentDate.split("T")[0];
        if (!acc[date]) {
          acc[date] = { sum: cur.overall_score, count: 1 };
        } else {
          acc[date].sum += cur.overall_score;
          acc[date].count += 1;
        }
        return acc;
      }, {});

      const lineDataArray = Object.entries(dateScoreMap).map(([date, { sum, count }]) => ({
        x: date,
        y: sum / count,
      }));

      const sortedLineDataArray = lineDataArray.sort((a, b) => {
        const dateA = new Date(a.x);
        const dateB = new Date(b.x);
        return dateA - dateB;
      });

      const lineData = [
        {
          id: "Overall Score",
          data: sortedLineDataArray,
        },
      ];
      setLineChartData(lineData);

      const totalScores = lineDataArray.reduce((sum, item) => sum + item.y, 0);
      const averageScore = totalScores / lineDataArray.length;
      setAverageOverallScore(averageScore);

      const uniquePatientIds = new Set(genderResponse.data.hcdata.map(item => item.patientID));
      const patientCount = uniquePatientIds.size;
      setTotalPatients(patientCount);

      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const columns1 = [
    {
      label: "Name",
      id: "name",
      key: "name",
    },

    {
      label: "APC ID",
      id: "apcid",
      key: "apcid",
    },

    {
      label: "Total Appointments",
      id: "count",
      key: "count",
      minWidth: "80px",
    },

    {
      label: "Status",
      id: "statuscount",
      key: "statuscount",
      minWidth: "80px",
      format: (data) => <StatusBars data={data} />,
    },
  ];

  const tableStyles = {
    borderCollapse: 'collapse',
    border: '1px solid #E0E0E0',
  };

  const tableRowStyles = {
    borderBottom: '1px solid #E0E0E0',
  };

  const tableCellStyles = {
    border: '1px solid #E0E0E0',
  };

  const ExpandableTableRow = ({ row }) => {
    const [open, setOpen] = useState(false);

    return (
      <>
        <TableRow>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              <ExpandMoreIcon />
            </IconButton>
          </TableCell>
          <TableCell>{row.appointment_id}</TableCell>
          <TableCell>{row.appointment_date.split("T")[0]}</TableCell>
          <TableCell>{`${row.first_name} ${row.last_name}`}</TableCell>
          <TableCell>{row.feedback.length > 0 ? row.feedback[0].apcid : ''}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table size="small" aria-label="feedback">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>Feedback Type</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Feedback</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Feedback Date</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>GUID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.feedback.map((feedbackRow) => (
                    <TableRow key={feedbackRow.id}>
                      <TableCell>{feedbackRow.feedbacktype}</TableCell>
                      <TableCell>{feedbackRow.feedback}</TableCell>
                      <TableCell>{feedbackRow.action}</TableCell>
                      <TableCell>{feedbackRow.feedbackDate}</TableCell>
                      <TableCell>{feedbackRow.guid}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const handleSearch = () => {
    getAPCApp();
    getData();
  };

  return (
    <div style={{ marginTop: "120px", marginLeft: "60px" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={(newValue) => {
            setStartDate(newValue);
          }}
          sx={{ marginRight: "20px", marginBottom: "20px" }}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          minDate={startDate}
          onChange={(newValue) => {
            setEndDate(newValue);
          }}
        />
      </LocalizationProvider>
      <TextField
        id="outlined-basic"
        label="Find"
        variant="outlined"
        onChange={(event) => setSearchTerm(event.target.value)}
        style={{ marginLeft: "20px", marginBottom: "20px" }}
      />
      <div style={{ display: "inline-flex", alignItems: "center" }}>
        <button
          onMouseDown={() => setIsButtonClicked(true)}
          onMouseUp={() => setIsButtonClicked(false)}
          onClick={handleSearch}
          style={{
            backgroundColor: "#002677",
            color: "white",
            borderRadius: "5px",
            padding: "10px 20px",
            border: "none",
            marginLeft: "40px",
            marginTop: "9px",
            cursor: "pointer",
            transform: isButtonClicked ? "scale(0.95)" : "scale(1)",
            transition: "transform 0.1s ease",
          }}
        >
          Search
        </button>
        {isLoading && (
          <div style={{ marginLeft: "10px", marginTop: "10px" }}>
            <CircularProgress size={20} />
          </div>
        )}
      </div>
      <div style={{ color: "#001D5B" }}>
        <h2> APC Appointment Status </h2>
      </div>
      <div>
        <div style={{ display: "flex", gap: "1%" }}>
          <div
            style={{
              marginBottom: "10px",
              width: "50%",
            }}
          >
            <div
              style={{
                marginBottom: "10px",
                paddingTop: "10px",
                height: "290px",
                width: "87.5%",
                border: "2px solid #e6e6e6",
                textAlign: "center",
              }}
            >
              <div>
                <b> Overall Status </b>
              </div>
              <MyResponsivePie
                totalCompleted={totalCompleted}
                totalScheduled={totalScheduled}
                totalApptScheduled={totalApptScheduled}
                totalRescheduled={totalRescheduled}
                totalCancelled={totalCancelled}
              />
            </div>

          </div>
          <div>
            <div
              style={{
                display: "flex",
                marginBottom: "10px",
                height: "120px",
                width: "31.6%",
                gap: "2%",
              }}
            >
              <AppointmentWidget
                title="Total Appointments"
                count={totalCompleted + totalScheduled + totalApptScheduled + totalRescheduled + totalCancelled}
                color="#003399"
                bgColor="#e6f7ff"
              />
              <AppointmentWidget
                title="Assesment Sent to CIP"
                count={totalCompleted}
                color="#00E396"
                bgColor="#e6fff7"
              />
              <AppointmentWidget
                title="Scheduled"
                count={totalScheduled}
                color="#ffbb33"
                bgColor="#fff7e6"
              />
              <AppointmentWidget
                title="Appointment Scheduled"
                count={totalApptScheduled}
                color="#ffbb33"
                bgColor="#fff7e6"
              />
              <AppointmentWidget
                title="Reschedule Member Called"
                count={totalRescheduled}
                color="#ffbb33"
                bgColor="#fff7e6"
              />
              <AppointmentWidget
                title="Cancelled Appointments"
                count={totalCancelled}
                color="#FF4560"
                bgColor="#ffe6e6"
              />
            </div>
            <div style={{ height: "2px", backgroundColor: "black", margin: "10px 0" }}></div>
            <div style={{ position: "relative", width: "100%" }}>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 700 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns1.map((column, index) => (
                          <TableCell
                            key={column.id}
                            style={{
                              width: 360,
                              borderRight: "1px solid #CBCCCD",
                              fontSize: "16px",
                              fontWeight: "700",
                              color: "#002677",
                              minWidth: column.minWidth,
                              textAlign:
                                index === columns1.length - 1
                                  ? "center"
                                  : "inherit",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data
                        .filter((row) => {
                          if (searchTerm === "") {
                            return true;
                          }

                          let lowerCaseSearchTerm = searchTerm.toLowerCase();

                          return (
                            (row.name &&
                              row.name
                                .toLowerCase()
                                .includes(lowerCaseSearchTerm)) ||
                            (row.apcid &&
                              row.apcid.toLowerCase().includes(lowerCaseSearchTerm))
                          );
                        })
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              {columns1.map((column) => {
                                const value = row[column.id];

                                return (
                                  <TableCell
                                    style={{
                                      width: 360,
                                      borderRight: "1px solid #CBCCCD",
                                      backgroundColor:
                                        column.id === "role" &&
                                        (value === "APC"
                                          ? "rgb(169 219 226 / 56%)"
                                          : value === "Super Admin"
                                            ? "#FEF9EA"
                                            : "#EFF6EF"),
                                    }}
                                    key={column.id}
                                  >
                                    {column.format ? column.format(value) : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    { label: "All", value: data.length },
                  ]}
                  component="div"
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          </div>
        </div>
      </div>
      <div style={{ color: "#001D5B" }}>
        <h2> Feedback Summary </h2>
      </div>
      <div style={{ display: "flex", marginBottom: "10px", height: "120px", width: "22%", gap: "20px" }}>
        <AppointmentWidget
          title="Total Appointments"
          count={feedbackStats.totalAppointments}
          color="#003399"
          bgColor="#e6f7ff"
        />
        <AppointmentWidget
          title="Total Feedbacks"
          count={feedbackStats.totalFeedbacks}
          color="#ffbb33"
          bgColor="#fff7e6"
        />
        <AppointmentWidget
          title="Positive Feedbacks"
          count={feedbackStats.positiveFeedbacks}
          color="#00E396"
          bgColor="#e6fff7"
        />
        <AppointmentWidget
          title="Negative Feedbacks"
          count={feedbackStats.negativeFeedbacks}
          color="#FF4560"
          bgColor="#ffe6e6"
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <Paper elevation={1}>
          <TableContainer>
            <Table aria-label="expandable table" style={tableStyles}>
              <TableHead>
                <TableRow style={tableRowStyles}>
                  <TableCell style={{ ...tableCellStyles, fontWeight: 'bold' }}>Expand</TableCell>
                  <TableCell style={{ ...tableCellStyles, fontWeight: 'bold' }}>Appointment ID</TableCell>
                  <TableCell style={{ ...tableCellStyles, fontWeight: 'bold' }}>Appointment Date</TableCell>
                  <TableCell style={{ ...tableCellStyles, fontWeight: 'bold' }}>APC Name</TableCell>
                  <TableCell style={{ ...tableCellStyles, fontWeight: 'bold' }}>APCID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {feedbackData
                  .slice(
                    feedbackPage * feedbackRowsPerPage,
                    feedbackPage * feedbackRowsPerPage + feedbackRowsPerPage
                  )
                  .map((row) => (
                    <ExpandableTableRow key={row.id} row={row} />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[
              5,
              10,
              25,
              { label: "All", value: feedbackData.length },
            ]}
            component="div"
            count={feedbackData.length}
            rowsPerPage={feedbackRowsPerPage}
            page={feedbackPage}
            onPageChange={handleFeedbackChangePage}
            onRowsPerPageChange={handleFeedbackChangeRowsPerPage}
          />
        </Paper>
      </div>
      <div style={{ marginTop: "20px" }}>
        <div style={{
          display: 'flex',
          gap: '4%',
          alignItems: 'center',
          marginBottom: "20px"
        }}>
          <h2 style={{ margin: 0, color: "#001D5B" }}>MLRB Match Percent</h2>
          <div style={{
            width: '20%',
            backgroundColor: '#b3ffd9',
            color: '#003399',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px',
            boxSizing: 'border-box',
            height: 'calc(1.5em + 10px)',
            borderRadius: '5px',
          }}>
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>Average Score: </span>{averageOverallScore.toFixed(3)}
          </div>
        </div>
        <div>
          <div style={{
            height: "370px",
            border: "2px solid #e6e6e6",
            width: "70%",
            marginBottom: "10px",
          }}>
            <div style={{ textAlign: "center" }}>
              <b> Overall Score </b>
            </div>
            <MyResponsiveLine data={lineChartData} />
          </div>
        </div>
        <div>
          <div style={{
            height: "370px",
            border: "2px solid #e6e6e6",
            width: "70%"
          }}>
            <div style={{ textAlign: "center" }}>
              <b> Individual Scores </b>
            </div>
            <MlrbResponsiveBar
              data={mlrbBarChartData}
              onBarClick={(bar) => {
                setSelectedBarSection(bar);
                setModalVisible(true);
              }}
            />
          </div>
        </div>
      </div>
      {
        modalVisible && (
          <BarDetailsModal
            open={modalVisible}
            onClose={() => setModalVisible(false)}
            barSection={selectedBarSection}
            data={mlrbData}
          />
        )
      }
      <div style={{ marginTop: "30px" }}>
        <div style={{
          display: 'flex',
          gap: '4%',
          alignItems: 'center',
          marginBottom: "20px"
        }}>
          <h2 style={{ margin: 0, color: "#001D5B" }}>Gender, Ethnicity, Race and Age Distribution</h2>
          <div style={{
            width: '20%',
            backgroundColor: '#b3ffd9',
            color: '#003399',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px',
            boxSizing: 'border-box',
            height: 'calc(1.5em + 10px)',
            borderRadius: '5px',
          }}>
            <span style={{ fontWeight: "bold", marginRight: "5px" }}>Total Patients: </span>{totalPatients}
          </div>
        </div>
        <div style={{ justifyContent: "center", gap: "1%" }}>
          <div
            style={{
              marginBottom: "10px",
              paddingTop: "10px",
              height: "370px",
              width: "70%",
              border: "2px solid #e6e6e6",
              textAlign: "center",
            }}
          >
            <div>
              <b> Gender, Ethnicity and Race Distribution </b>
            </div>
            <MyResponsiveRadialBar
              genderData={genderData}
              raceData={raceData}
              ethnicityData={ethnicityData}
            />
          </div>
          <div
            style={{
              marginBottom: "10px",
              paddingTop: "10px",
              height: "370px",
              width: "70%",
              border: "2px solid #e6e6e6",
              textAlign: "center",
            }}
          >
            <div>
              <b> Age Distribution </b>
            </div>
            <MyResponsiveBar data={barChartData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default APCApp;
